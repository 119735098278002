(function ($) {
	"use strict";

	 $(window).on('load', function(){
        // Prealoder
        $("#preloader").delay(500).fadeOut();
    });
	

    // Hamburger-menu
    $('.hamburger-menu, #menu li a').on('click', function () {
        $('.hamburger-menu, #menu').toggleClass('current');
    });

    // Hamburger-menu
    $('.hamburger-menu').on('click', function () {
        $('.hamburger-menu .line-top, .menu').toggleClass('current');
        $('.hamburger-menu .line-center').toggleClass('current');
        $('.hamburger-menu .line-bottom').toggleClass('current');
    });

	$('.video-popup').magnificPopup({
		type:'iframe',
	
	});
	AOS.init();
	 // nice select
	 $('select').niceSelect();
    	// owlCarousel
	    // marquee slider Initialize ----------

		$('.marquee').marquee({
			duration: 9000,
			delayBeforeStart: 0,
			duplicated: true,
			pauseOnHover: true,
	  
		  });


	// smooth scroll
    $('#menu li a').bind('click', function(event) {
        var $anchor = $(this);
        var headerH = $('.header-area').height();
        $('html, body').stop().animate({
        scrollTop: $($anchor.attr('href')).offset().top - headerH + "px"
        }, 500, 'easeInSine');
        event.preventDefault();
    });


    // Show or hide the sticky footer button
    $(window).on('scroll', function (event) {
        if ($(this).scrollTop() > 600) {
            $('.back-to-top').fadeIn(200)
        } else {
            $('.back-to-top').fadeOut(200)
        }
    });

    //Animate the scroll to top
    $('.back-to-top').on('click', function (event) {
        event.preventDefault();

        $('html, body').animate({
            scrollTop: 0,
        }, 1500);
    });

    $('.watch_btn').magnificPopup({
        type: 'iframe',
        iframe: {
            patterns: {
                youtube: {
                  index: 'youtube.com/', 

                  id: 'v=',
                  src: 'https://www.youtube.com/embed/%id%?autoplay=1'
                },
            },

          srcAction: 'iframe_src',
        }
    });
    
    $('.owl-carousel.slider1').owlCarousel({
        loop: false,
        margin: 15,
        autoplay: true,
        autoplayTimeout: 1500,
        autoplayHoverPause: true,
        nav: true,
        dots: false,
        responsive: {
            0: {
                items: 1
            },
            576:{
                items: 1,
                stagePadding: 50,
            },
            768: {
                items: 2
            },
            992: {
                items: 2,
                stagePadding: 70,
            }
        }
    });

})(jQuery);