import './bootstrap';
import Alpine from 'alpinejs';

import jQuery from 'jquery';
window.$ = jQuery;
window.jQuery = jQuery;

window.moment = require('moment');

import aos from 'aos';
window.AOS = aos;

window.Alpine = Alpine;

Alpine.start();

require('datatables.net-responsive-dt');

import Swal from 'sweetalert2/dist/sweetalert2.js';
window.Swal = Swal;

window.lozad = require('lozad');
//import Loader from './loader.js';
//window.Loader = Loader;

//var DataTable = require( 'datatables.net' );
//require( 'datatables.net-responsive' );
//import DataTable from 'datatables.net-dt';
//window.dt = DataTable;
//import DataTable from 'datatables.net-dt';

//window.dt = DataTable;
//window.DataTable = DataTable;
